import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";
import { navigate } from "gatsby";

const RegistrationListPage = observer(() => {
  const { rootStore, studentStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetStudent, setTargetStudent] = useState<any>();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [confirmEmailAddress, setConfirmEmailAddress] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) == 0 ||
      studentStore.studentProfileList.data.length > PAGE_SIZE) {
      studentStore.getStudentProfileList(PAGE_SIZE, 0, {});
    }
  }, []);

  const onActionStudentProfile = () => {
    if (emailAddress !== confirmEmailAddress) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    };
    let req: any = {
      id: 0,
      firstName,
      lastName,
      middleName,
      emailAddress,
      username,
      collegeId: GetCollegeId(),
    }
    if (targetStudent) {
      req.id = targetStudent.id;
      studentStore.updateStudentProfile(req).then(() => {
        rootStore.notify(t('STUDENT_PROFILE') + t('UPDATED_B'), 'success');
        studentStore.getStudentProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE, {});
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      studentStore.createStudentProfile(req).then(() => {
        rootStore.notify(t('STUDENT_PROFILE') + t('CREATED_B'), 'success')
        studentStore.getStudentProfileList(PAGE_SIZE, 0, {});
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = studentStore.studentProfileList.data.find(s => s.id == id);
      setTargetStudent(target);
      setFirstName(target.firstName);
      setLastName(target.lastName);
      setMiddleName(target.middleName);
      setEmailAddress(target.emailAddress);
      setUsername(target.username);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetStudent(null);
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setEmailAddress("");
    setUsername("");
  }

  const handleDeleteModal = (id: number) => {
    let target = studentStore.studentProfileList.data.find(s => s.id == id);
    setTargetStudent(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('REGISTRATION_LIST')}
      pageHeading={t('REGISTRATION_LIST')}
      breadCrumb={[
        { label: t('TRAINEESHIP') + t('OVERVIEW_B'), to: "" },
        { label: t('REGISTRATION_LIST'), to: "/traineeship/registration-list" }
      ]}
      buttonLabel={t('ADD_A') + t('STUDENT_PROFILE')}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={studentStore.loading}
        page={page}
        paginationCount={studentStore.studentProfileList.totalCount == 0 ? 1 : studentStore.studentProfileList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          studentStore.getStudentProfileList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE, {});
        }}
        thead={["ID", t('NAME'), t('USERNAME'), t('BOD'), t('EMAIL'), t('ACTION')]}
        tbody={studentStore.studentProfileList.data.length > 0 ?
          studentStore.studentProfileList.data.map((student, index) => (
            <>
              <Box marginY={1} />
              <tr key={`student_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{student.firstName + " " + student.lastName}</td>
                <td className={TD_NORMAL}>{student.username}</td>
                <td className={TD_NORMAL}>{student.birthDate.split('T')[0]}</td>
                <td className={TD_NORMAL}>{student.emailAddress}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => navigate("/student/profile",
                      { state: { studentId: student.id } })}
                  >
                    {t('VIEW')}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(student.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(student.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))
          : <tr><td className="text-center py-4" colSpan={6}>{t('NO_RESULT_FOUND')}</td></tr>}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        width={500}
        title={targetStudent ? t('UPDATE_A') + t('STUDENT_PROFILE') : t('ADD_A') + t('STUDENT_PROFILE')}
        buttonLabel={targetStudent ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetStudent ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionStudentProfile}
        disabledCondition={firstName === "" || lastName === "" || username === ""
          || confirmEmailAddress === "" || emailAddress === ""}
      >
        <div className="grid grid-cols-2 gap-4">
          <TextField
            value={username}
            label={t('USERNAME')}
            variant="outlined"
            disabled={targetStudent}
            sx={{ width: '100%' }}
            onChange={(value) => setUsername(value.target.value)}
          />
          <TextField
            value={firstName}
            label={t('FIRST_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setFirstName(value.target.value)}
          />
          <TextField
            value={lastName}
            label={t('LAST_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setLastName(value.target.value)}
          />
          <TextField
            value={middleName}
            label={t('MIDDLE_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={""}
            onChange={(value) => setMiddleName(value.target.value)}
          />
          <TextField
            value={emailAddress}
            label={t('EMAIL')}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={""}
            onChange={(value) => setEmailAddress(value.target.value)}
          />
          <TextField
            value={confirmEmailAddress}
            label={t('CONFIRM_EMAIL')}
            variant="outlined"
            sx={{ width: '100%' }}
            autoComplete={""}
            onChange={(value) => setConfirmEmailAddress(value.target.value)}
          />
        </div>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => studentStore.deleteStudentProfile(targetStudent.id).then(() => {
          rootStore.notify(t('STUDENT_PROFILE') + t('DELETED_B'), 'success');
          studentStore.getStudentProfileList(PAGE_SIZE, 0, {});
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_STUDENT')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default RegistrationListPage;
